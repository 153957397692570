<script setup lang="ts">
import { useRootStore } from "@/store/root";

const rootStore = useRootStore();
const { imageModal } = storeToRefs(rootStore);
</script>

<template>
  <Modal
    :show="imageModal.show"
    @close="rootStore.toggleShowImageModal({ active: false })"
    width="w-fit"
    max-width="max-w-full p-5"
  >
    <img :src="imageModal.imageUrl" alt="Profile Image" />
  </Modal>
</template>
